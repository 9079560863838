import React from 'react';

import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';

const ShareButtons = ({ url, title, size = 32 }) => {
  return (
    <div className="flex sm:justify-start justify-center items-center my-5">
      Share the word :
      <TwitterShareButton
        url={url}
        quote={title}
        via="melbarchany"
        className="ml-3"
      >
        <TwitterIcon size={size} round={true} />
      </TwitterShareButton>
      <LinkedinShareButton url={url} quote={title} className="ml-3">
        <LinkedinIcon size={size} round={true} />
      </LinkedinShareButton>
      <FacebookShareButton url={url} quote={title} className="ml-3">
        <FacebookIcon size={size} round={true} />
      </FacebookShareButton>
    </div>
  );
};

export default ShareButtons;
