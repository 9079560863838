import React from 'react';
import { graphql, Link } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';

import Layout from './../components/Layout';
import SEO from '../components/SEO';
import MDXComponents from '../components/mdx';
import ShareButtons from './../components/ShareButtons';

const BlogPost = ({ data, location, pageContext }) => {
  const post = data.mdx;
  const title = post.frontmatter.title;
  const date = post.frontmatter.date;
  const body = post.body;
  const thumbnail = post.frontmatter.thumbnail;
  const description = post.frontmatter.description;
  const timeToRead = post.timeToRead;
  // get static query for the metadata
  const postLink = post.fields.slug;
  const { next, previous } = pageContext;
  const nextArticle = next ? (
    <Link to={next.path}>
      <div className="text-right font-semibold underline">
        Next Article
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 490.8 490.8"
          className="h-3 w-3 inline-block ml-1"
        >
          <defs />
          <g fill="#009688">
            <path d="M231.696 3.128c-4.237-4.093-10.99-3.975-15.083.262-3.992 4.134-3.992 10.687 0 14.82l227.115 227.136-227.136 227.115c-4.237 4.093-4.355 10.845-.262 15.083 4.093 4.237 10.845 4.354 15.083.262.089-.086.176-.173.262-.262l234.667-234.667c4.164-4.165 4.164-10.917 0-15.083L231.696 3.128z" />
            <path d="M274.363 237.795L39.696 3.128a10.668 10.668 0 00-15.083 15.083l227.115 227.136L24.592 472.461c-4.237 4.093-4.354 10.845-.262 15.083 4.093 4.237 10.845 4.354 15.083.262.089-.086.176-.173.262-.262l234.667-234.667c4.169-4.159 4.179-10.911.021-15.082z" />
          </g>
          <path d="M224.144 490.68a10.667 10.667 0 01-7.552-18.219l227.136-227.115L216.592 18.232c-4.093-4.237-3.975-10.99.262-15.083 4.134-3.992 10.687-3.992 14.82 0l234.667 234.667c4.164 4.165 4.164 10.917 0 15.083L231.675 487.565a10.66 10.66 0 01-7.531 3.115z" />
          <path d="M32.144 490.68a10.667 10.667 0 01-7.552-18.219l227.136-227.115L24.592 18.232c-4.171-4.171-4.171-10.933 0-15.104s10.933-4.171 15.104 0l234.667 234.667c4.164 4.165 4.164 10.917 0 15.083L39.696 487.544a10.665 10.665 0 01-7.552 3.136z" />
        </svg>
      </div>
      <div className="text-right">{next.title}</div>
    </Link>
  ) : (
      <div></div>
    );

  const prevArticle = previous ? (
    <Link to={previous.path}>
      <div className="text-left font-semibold underline">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 490.787 490.787"
          className="h-3 w-3 inline-block mr-1"
        >
          <defs />
          <g fill="#009688">
            <path d="M47.087 245.454L274.223 18.339c4.237-4.093 4.355-10.845.262-15.083-4.093-4.237-10.845-4.355-15.083-.262-.089.086-.176.173-.262.262L24.474 237.923c-4.164 4.165-4.164 10.917 0 15.083L259.14 487.672c4.237 4.093 10.99 3.975 15.083-.262a10.666 10.666 0 000-14.821L47.087 245.454z" />
            <path d="M466.223 3.235c-4.165-4.164-10.917-4.164-15.083 0L216.474 237.902c-4.164 4.165-4.164 10.917 0 15.083L451.14 487.651c4.237 4.093 10.99 3.976 15.083-.261 3.993-4.134 3.993-10.688 0-14.821L239.087 245.454 466.223 18.339c4.171-4.16 4.179-10.914.019-15.085l-.019-.019z" />
          </g>
          <path d="M266.671 490.787a10.663 10.663 0 01-7.552-3.115L24.452 253.006c-4.164-4.165-4.164-10.917 0-15.083L259.119 3.256c4.093-4.237 10.845-4.355 15.083-.262 4.237 4.093 4.355 10.845.262 15.083-.086.089-.173.176-.262.262L47.087 245.454l227.136 227.115c4.171 4.16 4.179 10.914.019 15.085a10.67 10.67 0 01-7.571 3.133z" />
          <path d="M458.671 490.787a10.663 10.663 0 01-7.552-3.115L216.452 253.006c-4.164-4.165-4.164-10.917 0-15.083L451.119 3.256c4.093-4.237 10.845-4.354 15.083-.262 4.237 4.093 4.354 10.845.262 15.083-.086.089-.173.176-.262.262L239.087 245.454l227.136 227.115c4.171 4.16 4.179 10.914.019 15.085a10.67 10.67 0 01-7.571 3.133z" />
        </svg>
        Previous Article
      </div>
      <div className="text-left">{previous.title}</div>
    </Link>
  ) : (
      <div></div>
    );

  return (
    <Layout>
      <SEO
        location={location.pathname}
        title={title}
        image={thumbnail.childImageSharp.fluid.src}
        description={description}
        article
      />
      <div className="container mx-auto max-w-3xl bg-white rounded-md shadow overflow-hidden">
        <Img fluid={thumbnail.childImageSharp.fluid} />
        <h1 className="text-3xl font-bold mt-3 p-3">{title}</h1>
        <p className="text-gray-600 flex items-center pt-3 pl-3">
          <svg
            className="h-4 w-4 fill-current"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm0 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm-1.063 1.875l-.437 8.188.094.874 6.156 5.438.969-1-5.094-5.406-.438-8.094z" />
          </svg>
          <span className="ml-2 text-sm">
            {date} - {timeToRead} min read
          </span>
        </p>
        <div className="w-full p-5">
          <MDXProvider components={MDXComponents}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </div>
        <div className="sm:flex sm:justify-between sm:items-center my-5 p-5">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://mobile.twitter.com/search?q=${encodeURIComponent(
              `https://melbarch.com${postLink}`)}`}
          >
            <div className="flex justify-center">
              <div className="items-center rounded-md border py-1 px-2  text-white bg-blue-500 hover:text-blue-500 hover:bg-white border-blue-500">
                <svg
                  className="inline-block h-5 w-5 fill-current"
                  viewBox="0 0 612 612"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M612 116.258a250.714 250.714 0 01-72.088 19.772c25.929-15.527 45.777-40.155 55.184-69.411-24.322 14.379-51.169 24.82-79.775 30.48-22.907-24.437-55.49-39.658-91.63-39.658-69.334 0-125.551 56.217-125.551 125.513 0 9.828 1.109 19.427 3.251 28.606-104.326-5.24-196.835-55.223-258.75-131.174-10.823 18.51-16.98 40.078-16.98 63.101 0 43.559 22.181 81.993 55.835 104.479a125.556 125.556 0 01-56.867-15.756v1.568c0 60.806 43.291 111.554 100.693 123.104-10.517 2.83-21.607 4.398-33.08 4.398-8.107 0-15.947-.803-23.634-2.333 15.985 49.907 62.336 86.199 117.253 87.194-42.947 33.654-97.099 53.655-155.916 53.655-10.134 0-20.116-.612-29.944-1.721 55.567 35.681 121.536 56.485 192.438 56.485 230.948 0 357.188-191.291 357.188-357.188l-.421-16.253c24.666-17.593 46.005-39.697 62.794-64.861z" />
                </svg>
                <span className="border-r border-blue-300 mx-2"></span>Discuss
                on Twitter
            </div>
            </div>
          </a>
          <ShareButtons
            url={`https://melbarch.com/${postLink}`}
            title={title}
            size="24"
          />
        </div>

        <div className="sm:flex sm:justify-between items-center my-5 p-5">
          {prevArticle}
          {nextArticle}
        </div>
      </div>
      <br />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 630) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default BlogPost;
